import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from './firebaseConfig';
import {
  doc,
  collection,
  getDoc,
  getDocs,
  updateDoc,
  where,
  query,
  onSnapshot,
} from 'firebase/firestore';
import { Center } from '@chakra-ui/react';
import LoadingProgress from './components/LoadingProgress';

const AuthContext = createContext();

// // 2つのオブジェクトが等しいかどうかを深く比較する関数
// const isEqual = (obj1, obj2) => {
//   const obj1Keys = Object.keys(obj1);
//   const obj2Keys = Object.keys(obj2);

//   if (obj1Keys.length !== obj2Keys.length) {
//     return false;
//   }

//   for (let key of obj1Keys) {
//     if (obj1[key] !== obj2[key]) {
//       return false;
//     }
//   }

//   return true;
// };

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeUserDoc = null;

    const unsubscribeAuth = auth.onAuthStateChanged(async user => {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);

        unsubscribeUserDoc = onSnapshot(userDocRef, async userDocSnap => {
          if (!userDocSnap.exists()) {
            console.log('ユーザー情報が見つかりません。');
            setCurrentUser(null);
            setLoading(false);
            return;
          }

          const userData = {
            id: userDocSnap.id,
            ...userDocSnap.data(),
          };

          // サブコレクションの参照を取得
          const ticketsCollectionRef = collection(userDocRef, 'tickets');
          const ticketsSnapshot = await getDocs(ticketsCollectionRef);

          const today = new Date(); // 現在の日時を取得

          // サブコレクション内の各ドキュメントを取得し、ソート
          const tickets = ticketsSnapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data(),
            }))
            // 未使用のチケットのみフィルタリング
            .filter(ticket => !ticket.used)
            .filter(ticket => {
              // チケットの期限を取得
              const expireDate = ticket.expire.toDate(); // Firestore TimestampをJavaScriptのDateに変換
              // 期限が現在時刻よりも後のチケットのみをフィルタリング
              return expireDate > today;
            })
            // 期限が近く、planチケットを優先してソート
            .sort((a, b) => {
              // planチケットを優先
              if (a.type === 'plan' && b.type !== 'plan') {
                return -1;
              } else if (a.type !== 'plan' && b.type === 'plan') {
                return 1;
              } else {
                // 両方が同じタイプの場合、期限が近いものを優先
                return a.expire.seconds - b.expire.seconds;
              }
            });

          let studio = null;
          if (userData.studioId) {
            const studioDocRef = doc(firestore, 'studios', userData.studioId);
            const studioDocSnap = await getDoc(studioDocRef);
            if (studioDocSnap.exists()) {
              studio = {
                id: studioDocSnap.id,
                ...studioDocSnap.data(),
              };
            }
          }

          const customerDocRef = doc(firestore, 'customers', user.uid);
          const customerDocSnap = await getDoc(customerDocRef);
          const customerData = customerDocSnap.exists()
            ? customerDocSnap.data()
            : {};

          // 顧客のサブスクリプションコレクションへの参照を取得
          const subscriptionColRef = collection(
            customerDocRef,
            'subscriptions'
          );

          // サブスクリプションのクエリを実行
          const subscriptionSnapshot = await getDocs(subscriptionColRef);
          let subscription = null;
          // サブスクリプションドキュメントが存在する場合、最初のドキュメントを取得
          if (!subscriptionSnapshot.empty) {
            const subscriptionDoc = subscriptionSnapshot.docs[0];
            subscription = subscriptionDoc.data();
          }

          const subscriptionId = subscription?.items?.[0]?.subscription;
          const priceId = subscription?.items?.[0]?.price?.id;
          const plan = subscription?.items?.[0]?.price?.product?.metadata?.plan;

          if (plan && Number(plan) !== userData.plan) {
            // ユーザードキュメントを更新
            await updateDoc(userDocRef, { plan: Number(plan) });
          }

          // 以下、その他の処理を含める

          const now = new Date(); // 現在の日時を取得
          const nineHoursInMilliseconds = 9 * 60 * 60 * 1000; // 9時間をミリ秒で計算
          const newTime = new Date(now.getTime() + nineHoursInMilliseconds); // 現在の時間に9時間を足す
          const newTimeISOString = newTime.toISOString(); // ISO文字列としてフォーマット

          let reservations = [];
          if (userData.studioId) {
            // 条件にマッチするドキュメントを検索するクエリを作成
            const q = userData.instructor
              ? query(
                  collection(firestore, 'reservations'),
                  where('studioId', '==', userData.studioId),
                  where('endDate', '>', newTimeISOString),
                  where('active', '==', true)
                )
              : query(
                  collection(firestore, 'reservations'),
                  where('userId', '==', user.uid),
                  where('studioId', '==', userData.studioId),
                  where('endDate', '>', newTimeISOString),
                  where('active', '==', true)
                );

            // クエリを実行してドキュメントを取得
            const reservationsSnapshot = await getDocs(q);
            reservations = reservationsSnapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => {
                // ここでTimestamp型のstartDateを比較してソート
                const dateA = a.startDate.toDate
                  ? a.startDate.toDate()
                  : new Date(a.startDate);
                const dateB = b.startDate.toDate
                  ? b.startDate.toDate()
                  : new Date(b.startDate);

                return dateA.getTime() - dateB.getTime(); // 昇順ソート
              });
          }

          // settingsからメンテナンスモードの設定を取得
          const settingsRef = doc(firestore, 'settings', 'maintenanceMode');
          const settingsDoc = await getDoc(settingsRef);
          const maintenanceMode = settingsDoc.data().isActive;

          const announceRef = doc(firestore, 'settings', 'announce');
          const announceDoc = await getDoc(announceRef);
          const announce = studio ? announceDoc.data()?.[studio.id] || '' : '';

          // サブコレクションを含む完全なユーザーデータを返す
          setCurrentUser({
            ...user,
            emailVerified: userData.emailVerified || user.emailVerified,
            ...userData,
            tickets,
            studio,
            reservations,
            stripeId: customerData.stripeId || null,
            subscriptionId,
            priceId,
            maintenanceMode,
            announce,
          });

          setLoading(false);
        });
      } else {
        setCurrentUser(null);
        setLoading(false);
        if (unsubscribeUserDoc) {
          unsubscribeUserDoc();
        }
      }
    });

    return () => {
      if (unsubscribeUserDoc) {
        unsubscribeUserDoc();
      }
      unsubscribeAuth();
    };
  }, []);

  const value = {
    currentUser,
    auth, // auth オブジェクトをコンテキストの値に追加
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <Center h="100vh">
          <LoadingProgress text="サーバー通信中..." />
        </Center>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
